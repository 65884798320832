import { gql } from '@apollo/client';
import { modules } from '@config';

export const createCartIdGuest = gql`
    mutation {
        createEmptyCart
    }
`;

export const getCartIdUser = gql`
    {
        customerCart {
            id
        }
    }
`;

export const createEmptyCartGuest = gql`
    mutation {
        createEmptyCart
    }
`;

export const addSimpleProductsToCart = gql`
mutation addSimpleProductsToCart(
    $cartId: String!,
    $qty: Float!,
    $sku: String!,
    ${modules.product.customizableOptions.enabled
        ? `
      $customizable_options: [CustomizableOptionInput],
      $entered_options: [EnteredOptionInput] 
    ` : ''}
) {
    addSimpleProductsToCart(input:{
      cart_id: $cartId,
      cart_items: {
        ${modules.product.customizableOptions.enabled
        ? ' customizable_options: $customizable_options' : ''}
        data: {
          quantity: $qty,
          sku: $sku,
          ${modules.product.customizableOptions.enabled
        ? ' entered_options: $entered_options' : ''}
        }
      }
    }) {
      cart {
        id
      }
    }
  }
`;

export const addVirtualProductToCart = gql`
mutation addVirtualProductToCart(
    $cartId: String!,
    $qty: Float!,
    $sku: String!,
    ${modules.product.customizableOptions.enabled
        ? `
      $customizable_options: [CustomizableOptionInput],      
      $entered_options: [EnteredOptionInput] 
      ` : ''}
) {
    addVirtualProductsToCart(input:{
      cart_id: $cartId,
      cart_items: {
        ${modules.product.customizableOptions.enabled
        ? ' customizable_options: $customizable_options' : ''}
        data: {
          quantity: $qty,
          sku: $sku,
          ${modules.product.customizableOptions.enabled
        ? ' entered_options: $entered_options' : ''}
        }
      }
    }) {
      cart {
        id
      }
    }
  }
`;

export const addDownloadableProductsToCart = gql`
mutation(
  $cartId : String!,
  $sku: String!,
  $qty: Float!,
  $download_product_link: [DownloadableProductLinksInput],
  ${modules.product.customizableOptions.enabled
        ? `
    $customizable_options: [CustomizableOptionInput],      
    $entered_options: [EnteredOptionInput] 
    ` : ''}
) {
  addDownloadableProductsToCart(
    input: {
      cart_id: $cartId
      cart_items: {
        ${modules.product.customizableOptions.enabled
        ? ' customizable_options: $customizable_options' : ''}
        data: {
          sku: $sku,
          quantity: $qty,
          ${modules.product.customizableOptions.enabled
        ? ' entered_options: $entered_options' : ''}
        }
        downloadable_product_links: $download_product_link
      }
    }
  ) {
    cart {
      items {
        product {
          sku
        }
        quantity
        ... on DownloadableCartItem {
          links {
            title
            price
          }
          samples {
            title
            sample_url
          }
        }
      }
    }
  }
}
`;

export const addConfigProductsToCart = gql`
mutation (
  $cartId: String!,
  $qty: Float!,
  $sku: String!,
  $parentSku: String!,  
  ${modules.product.customizableOptions.enabled
        ? `
    $customizable_options: [CustomizableOptionInput],
    $entered_options: [EnteredOptionInput] 
  ` : ''}
) {
  addConfigurableProductsToCart(
    input: {
      cart_id: $cartId,
      cart_items: {
        ${modules.product.customizableOptions.enabled
        ? ' customizable_options: $customizable_options' : ''}
        data: {
          quantity : $qty,
          sku: $sku,
          ${modules.product.customizableOptions.enabled
        ? ' entered_options: $entered_options' : ''}            
        parent_sku: $parentSku
        }
      }
    }
  ) {
    cart {
      id
    }
  }
}
`;

export const addConfigurableProductsToCart = gql`
  mutation addConfigurableProductsToCart(
    $cartId: String!,
    $cartItems: [CartItemInput!]!
  ) {
    addProductsToCart(cartId: $cartId, cartItems: $cartItems) {
      cart {
        id
      }
      user_errors {
        code
        message
      }
    }
  }
`;

export const addBundleProductsToCart = gql`
mutation (
  $cartId: String!,
  $cartItems: [BundleProductCartItemInput]!,
) {
      addBundleProductsToCart(
        input: {
          cart_id: $cartId
          cart_items: $cartItems
        }
      ) {
        cart {
          id
        }
      }
    }
`;

export const addProductToCart = gql`
    mutation addProductToCart(
        $cartId: String!,
        $cartItems: [CartItemInput!]!
    ) {
        addProductsToCart(
            cartId: $cartId
            cartItems: $cartItems
        ) {
            cart {
                id
            }
            user_errors {
                code
                message
            }
        }
    }
`;

export const addGiftCardProductsToCart = gql`
mutation addGiftCardProductsToCart(
    $cartId: String!,
    $qty: Float!,
    $sku: String!,
    $awGcInput: awGcGiftCardOptionInput!,
    ${modules.product.customizableOptions.enabled
        ? `
      $customizable_options: [CustomizableOptionInput],
      $entered_options: [EnteredOptionInput] 
    ` : ''}
) {
    addAwGcProductToCart(input: {
      cart_id: $cartId,
      cart_items: {
        ${modules.product.customizableOptions.enabled
        ? ' customizable_options: $customizable_options' : ''}
        data: {
          quantity: $qty,
          sku: $sku,
          ${modules.product.customizableOptions.enabled
        ? ' entered_options: $entered_options' : ''}
        }
        aw_giftcard_option: $awGcInput
      }
    }) {
      cart {
        id
        items {
          ... on AwGiftCardCartItem {
            quantity
            prices {
              price {
                value
              }
            }
            product {
              name
            }
          }
        }
      }
    }
  }
`;

export const buyNow = gql`
    mutation Cart($cartId: String!, $cartItems: [CartItemInput!]!) {
      buyNow(cartId: $cartId, cartItems: $cartItems) {
        cart {
          items {
            product {
              sku
              name
            }
          }
          dest_location {
            dest_latitude
            dest_longitude
          }
          pickup_store_person {
            email
            handphone
            name
          }
          available_payment_methods {
            code
            title
            term_options {
              label
              value
            }
          }
          id
          email
          applied_cashback {
            data {
              amount
              promo_name
            }
            is_cashback
            total_cashback
          }
          applied_reward_points {
            is_use_reward_points
            reward_points_amount
          }
          applied_coupons {
            code
          }
          applied_extra_fee {
            extrafee_value {
              currency
              value
            }
            select_options {
              default
              label
              option_id
              price
            }
            show_on_cart
            title
          }
          addtional_fees {
            data {
              enabled
              fee_name
              frontend_type
              id_fee
              options {
                default
                label
                option_id
                price
              }
            }
            show_on_cart
          }
          applied_giftcard {
            giftcard_amount
            giftcard_detail {
              giftcard_amount_used
              giftcard_code
            }
          }
          applied_store_credit {
            store_credit_amount
            is_use_store_credit
          }
          prices {
            discounts {
              amount {
                currency
                value
              }
              label
            }
            subtotal_excluding_tax {
              currency
              value
            }
            subtotal_including_tax {
              currency
              value
            }
            applied_taxes {
              amount {
                value
                currency
              }
            }
            grand_total {
              currency
              value
            }
          }
          available_free_items {
            sku
            quantity
            promo_item_data {
              ruleId
              minimalPrice
              discountItem
              isDeleted
              qtyToProcess
              isAuto
            }
          }
          promoBanner {
            cms_block_id
            name
            cms_block_identifier
            rule_id
          }
          shipping_addresses {
            is_valid_city
            seller_id
            firstname
            lastname
            street
            city
            postcode
            telephone
            region {
              code
              label
              region_id
            }
            company
            country {
              code
              label
            }
            selected_shipping_method {
              method_code
              carrier_code
              amount {
                value
                currency
              }
            }
            available_shipping_methods {
              available
              method_code
              carrier_code
              method_title
              carrier_title
              amount {
                value
                currency
              }
              shipping_promo_name
              error_message
              price_incl_tax {
                value
              }
            }
          }
          billing_address {
            city
            company
            country {
              code
              label
            }
            firstname
            lastname
            postcode
            region {
              code
              label
            }
            street
            telephone
          }
          selected_payment_method {
            code
            purchase_order_number
            title
          }
        }
      }
    }
`;

export const addProductAlertStock = gql`
  mutation addProductAlertStock($sku: String!) {
    addProductAlertStock(sku: $sku)
  }
`;

export default {
    createCartIdGuest,
};
